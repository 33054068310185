<template>
  <div
    v-if="props.buttons?.length"
    class="gap-x-5 text-white lg:flex"
    data-el="t3ce-rawlplug-horizontalbuttons"
  >
    <div
      v-if="props.header"
      class="mb-3 shrink-0 text-lg font-bold lg:mb-0"
    >
      {{ props.header }}
    </div>

    <div class="hidden flex-wrap gap-3.5 lg:flex">
      <template
        v-for="(item, index) in props.buttons"
        :key="`horizontalbutton-${index}`"
      >
        <UiButton
          v-if="item?.link?.href && item?.label"
          :to="item.link.href"
          variant="transparent"
          :uppercase="false"
          slim
          icon-right="arrow"
          :icon-dimensions="{
            width: 7,
            height: 10,
          }"
          class="border py-2 font-medium leading-4"
        >
          {{ item.label }}
        </UiButton>
      </template>
    </div>
    <div class="-mr-8 lg:mr-0 lg:hidden">
      <UiSliderHorizontal
        classes="swiper-main scrollbar-primary"
        :show-navigation-buttons="false"
        :options="sliderOptions"
      >
        <template
          v-for="(item, index) in props.buttons"
          :key="`horizontalbutton-${index}`"
        >
          <NuxtLink
            v-if="item?.link?.href && item?.label"
            :to="item.link.href"
            class="flex aspect-square shrink-0 grow flex-col items-center justify-center bg-white px-2.5 py-3"
          >
            <UiIcon
              v-if="item?.icon"
              :name="item.icon"
              class="mb-2.5 text-primary"
              height="25"
              width="25"
            />
            <p
              class="flex min-h-9 items-center text-center text-sm font-medium leading-4 text-primary"
            >
              {{ item.label }}
            </p>
          </NuxtLink>
        </template>
      </UiSliderHorizontal>
    </div>
  </div>
</template>

<script lang="ts" setup>
import UiButton from '@ui/components/UiButton/UiButton.vue'
import UiIcon from '@ui/components/UiIcon/UiIcon.vue'
import UiSliderHorizontal from '@ui/components/UiSlider/UiSliderHorizontal.vue'
import type { T3CeRawlplugHorizontalButtonsProps } from './T3CeRawlplug_horizonstalbuttons.types'

const props = withDefaults(defineProps<T3CeRawlplugHorizontalButtonsProps>(), {
  header: '',
})

const sliderOptions = computed(() => {
  return {
    spaceBetween: 15,
    scrollbar: false,
    breakpoints: {
      360: {
        slidesPerView: 3.23,
      },
      480: {
        slidesPerView: 4.25,
      },
      640: {
        slidesPerView: 5.25,
      },
      768: {
        slidesPerView: 6.25,
      },
    },
  }
})
</script>

<style lang="postcss">
[data-el='t3ce-rawlplug-horizontalbuttons'] {
  & .swiper-main {
    @apply !pb-0;
  }
}
</style>
